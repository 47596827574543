<template>
	<div class="page" style="background: #C6BCAF; width: 100%; height: 100vh; overflow: hidden;">
		<div class="grid" style="background: #918477">
			<div class="col-8 col-offset-2">
				<div class="grid" style="border: #97706B; border-style: solid; border-width: 0px;border-bottom-width: 2px;">
					<div class="col-12" style="background: #4E2E2A; padding-top: 15px;">
						<div class="grid">
							<div class="col-6">
								<span style="margin-left: 10px; margin-right:10px; color: #FFFFFF">VÁCI EGYHÁZMEGYE KÓRHÁZLELKÉSZI SZOLGÁLATA</span>
							</div>
							<div class="col-6" style="text-align: right;">
								<span style="margin-left: 10px; margin-right:10px;  color: #FFFFFF">Vác, Migazzi tér 1. (+36) 27/814-114 Fax: (+36) 27/814-101</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="grid" style="background: linear-gradient(
							to bottom,
							#918477 0,
							#918477 196px,
							white 196px,
							white 100%
						); padding-top:0px; padding-bottom:0px;"
			>
			<div class="col-8 col-offset-2">
				<div class="grid" style="background: #C6BCAF">
					<div class="col-fix" style="padding-top: 0px; padding-bottom: 0px; padding: 0px; width: 198px; height: 252px">
						<img src="images/korhazlelkesz_logo.jpg" />
					</div>
					<div class="col" style="padding-top:0px; padding-bottom: 0px; background: #C6BCAF">
						<Galleria :value="images" :responsiveOptions="responsiveOptions" :numVisible="5" :circular="true" containerStyle="max-width: 1024px"
							:showItemNavigators="true" :showThumbnails="false" :showItemNavigatorsOnHover="true" :showIndicators="true"
							:changeItemOnIndicatorHover="true" :showIndicatorsOnItem="false" indicatorsPosition="top"
							:autoPlay="true" :transitionInterval="5000"
							>
							<template #item="slotProps">
								<a href="https://www.facebook.com/korhazlelkesz"><img :src="slotProps.item.itemImageSrc" :alt="slotProps.item.alt" style="width: 100%; display: block;" /></a>
							</template>
							<template #thumbnail="slotProps">
									<img :src="slotProps.item.thumbnailImageSrc" :alt="slotProps.item.alt" style="display: block;" />
							</template>
						</Galleria>
					</div>
				</div>
			</div>
		</div>
		<div class="grid" style="background: #C6BCAF">
			<div class="col-8 col-offset-2" style="background: white; height: 100vh;">
				<card>
					<template #title> Kedves Látogató! </template>
					<template #content>
						<p>
							A Váci Egyházmegye Kórházlelkészi Szolgálatának honlapja rekonstrukció alatt áll.<br />
							Amennyiben további információra, segítségre lenne szüksége, hívja ezt a telefonszámot: 06 30 194-4711 <br />
							Vagy látogasson el Facebook oldalunkra: <b><a href="https://www.facebook.com/korhazlelkesz" style="color:#918477">https://www.facebook.com/korhazlelkesz</a></b>
						</p>
					</template>
				</card>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    data() {
        return {
			images: [
				{
					itemImageSrc: '/images/fejlecek_01.jpg',
					thumbnailImageSrc: '/images/fejlecek_01.jpg',
					alt: 'Description for Image 1',
					title: 'Title 1'
				},
				{
					itemImageSrc: '/images/fejlecek_02.jpg',
					thumbnailImageSrc: '/images/fejlecek_02.jpg',
					alt: 'Description for Image 2',
					title: 'Title 2'
				},
				{
					itemImageSrc: '/images/fejlecek_04.jpg',
					thumbnailImageSrc: '/images/fejlecek_04.jpg',
					alt: 'Description for Image 4',
					title: 'Title 4'
				},
				{
					itemImageSrc: '/images/fejlecek_05.jpg',
					thumbnailImageSrc: '/images/fejlecek_05.jpg',
					alt: 'Description for Image 5',
					title: 'Title 5'
				},
				{
					itemImageSrc: '/images/fejlecek_13.jpg',
					thumbnailImageSrc: '/images/fejlecek_13.jpg',
					alt: 'Description for Image 13',
					title: 'Title 13'
				},
				{
					itemImageSrc: '/images/fejlecek_15.jpg',
					thumbnailImageSrc: '/images/fejlecek_15.jpg',
					alt: 'Description for Image 15',
					title: 'Title 15'
				},
				{
					itemImageSrc: '/images/fejlecek_17.jpg',
					thumbnailImageSrc: '/images/fejlecek_17.jpg',
					alt: 'Description for Image 17',
					title: 'Title 17'
				},
			],
            responsiveOptions: [
                {
                    breakpoint: '991px',
                    numVisible: 4
                },
                {
                    breakpoint: '767px',
                    numVisible: 3
                },
                {
                    breakpoint: '575px',
                    numVisible: 1
                }
            ]			

		}
	},
    components: {
    }
}
</script>

<style lang="scss">
@import './App.scss';
</style>

<style scoped>

</style>
